<template>
  <main>
    <header
      class="page-header page-header-compact page-header-light border-bottom bg-white mb-4"
    >
      <div class="container-fluid">
        <div class="page-header-content">
          <div class="row align-items-center justify-content-between pt-3">
            <div class="col-auto mb-3">
              <h1 class="page-header-title">
                <div class="page-header-icon"></div>
                Factura a EPS
              </h1>
            </div>
            <div class="col-12 col-xl-auto mb-3">
              <button class="btn btn-success shadow-sm" @click="guardar()">
                <i class="far fa-save text-white-50"></i> Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- Main page content-->
    <div class="container mt-4">
      <div class="row">
        <div class="col-10 offset-1">
          <div class="card">
            <div class="card-header text-uppercase">Datos Factura</div>
            <div class="card-body">
              <form>
                <div class="form-row">
                  <div class="col-8 form-group">
                    <label class="small mb-1">Eps (*)</label>
                    <v-select-eps
                      :mode="'object'"
                      v-model="eps"
                      :validator="$v.eps"
                    ></v-select-eps>
                    <span class="invalid-feedback" v-if="!$v.eps.required"
                      >Este campo es requerido</span
                    >
                  </div>
                  <div class="col-4 form-group">
                    <label class="small mb-1"
                      >Contrato <small>(*)</small></label
                    >
                    <select
                      name="contratos"
                      id="contratos"
                      class="form-control form-control-sm"
                      v-model="contrato"
                      :class="{
                        'is-invalid': $v.contrato.$error && $v.contrato.$dirty,
                      }"
                    >
                      <option selected disabled>
                        -- Seleccione una opcion --
                      </option>
                      <option
                        v-for="contrato in contratos"
                        :key="contrato.id"
                        :value="contrato"
                      >
                        {{ contrato.codigo }} - {{ contrato.descripcion }}
                      </option>
                    </select>
                    <span class="invalid-feedback" v-if="!$v.contrato.required"
                      >Este campo es requerido</span
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-4 form-group">
                    <label class="small mb-1">Inicio Periodo (*)</label>
                    <input
                      type="date"
                      v-model="periodo_inicio"
                      class="form-control form-control-sm"
                      :class="{
                        'is-invalid':
                          $v.periodo_inicio.$error && $v.periodo_inicio.$dirty,
                      }"
                    />
                    <span
                      class="invalid-feedback"
                      v-if="!$v.periodo_fin.$required"
                      >Este campo es requerido</span
                    >
                  </div>
                  <div class="col-4 form-group">
                    <label class="small mb-1">Fin Periodo (*)</label>
                    <input
                      type="date"
                      v-model="periodo_fin"
                      class="form-control form-control-sm"
                      :class="{
                        'is-invalid':
                          $v.periodo_fin.$error && $v.periodo_fin.$dirty,
                      }"
                    />
                    <span
                      class="invalid-feedback"
                      v-if="!$v.periodo_fin.$required"
                      >Este campo es requerido</span
                    >
                  </div>
                  <div class="col-4 form-group">
                    <label class="small mb-1">Población (*)</label>
                    <input
                      type="number"
                      v-model="poblacion"
                      class="form-control form-control-sm"
                      :class="{
                        'is-invalid':
                          $v.poblacion.$error && $v.poblacion.$dirty,
                      }"
                    />
                    <span
                      class="invalid-feedback"
                      v-if="!$v.poblacion.$required"
                      >Este campo es Requerido</span
                    >
                    <span class="invalid-feedback" v-if="$v.poblacion.$numeric"
                      >Este campo solo puede contener numeros</span
                    >
                    <span
                      class="invalid-feedback"
                      v-if="!$v.poblacion.$minValue"
                      >La poblacion no debe ser menor a 1</span
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-4 form-group">
                    <label class="small mb-1">Actividad (*)</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="actividad"
                      :class="{
                        'is-invalid':
                          $v.actividad.$error && $v.actividad.$dirty,
                      }"
                    >
                      <option value="null" selected disabled>
                        -- Seleccione la actividad --
                      </option>
                      <option
                        v-for="(actividad, index) in actividades"
                        :key="index"
                        :value="actividad.id"
                      >
                        {{ actividad.descripcion }}
                      </option>
                    </select>
                    <span
                      class="invalid-feedback"
                      v-if="!$v.actividad.$required"
                      >Este campo es requerido</span
                    >
                  </div>
                  <div class="col-8">
                    <label class="small mb-1">Observaciones</label>
                    <textarea
                      rows="3"
                      class="form-control form-control-sm"
                      v-model="observaciones"
                    ></textarea>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-12">
                    <label class="small mb-1">Valor Facturado (*)</label>
                    <input
                      type="number"
                      step="0.1"
                      v-model="valor_facturado"
                      class="form-control form-control-lg"
                      :class="{
                        'is-invalid':
                          $v.valor_facturado.$error &&
                          $v.valor_facturado.$dirty,
                      }"
                    />
                    <span
                      class="invalid-feedback"
                      v-if="!$v.valor_facturado.required"
                      >Este campo es requerido</span
                    >
                    <span
                      class="invalid-feedback"
                      v-if="!$v.valor_facturado.decimal"
                      >El valor debe ser numerico</span
                    >
                    <span
                      class="invalid-feedback"
                      v-if="!$v.valor_facturado.minValue"
                      >El valor a facturar no puede ser cero</span
                    >
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script type="text/javascript">
import { required, minValue, numeric, decimal } from "vuelidate/lib/validators";
import Swal from "sweetalert2/src/sweetalert2.js";

import VSelectEps from "../../../components/common/VSelectEps";
import facturaService from "../../../services/facturaService";
import Axios from "axios";
import {isEmpty} from 'lodash';

const host = process.env.MIX_BASE_PATH;
const basePath = "/api";

const service = Axios.create({
  withCredentials: true,
  baseURL: `${host}${basePath}`,
});

export default {
  components: {
    VSelectEps,
  },
  created() {
      this.getActivities();
  },
  data: () => ({
    eps: {},
    contrato: {},
    periodo_inicio: "",
    periodo_fin: "",
    poblacion: 0,
    actividad: "",
    observaciones: "",
    valor_facturado: 0,
    item: {},
    contratos: [],
    actividades: [],
  }),
  validations() {
    return {
      eps: { required },
      contrato: { required },
      periodo_inicio: { required },
      periodo_fin: { required },
      poblacion: { required, numeric },
      actividad: { required },
      valor_facturado: { required, minValue: minValue(1), decimal },
    };
  },
  methods: {
    onSelectEps(value) {
      this.eps = value;
    },
    async guardar() {
      try {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          let factura = {
            id_eps: this.eps.id,
            id_contrato: this.contrato.id,
            periodo_inicio: this.periodo_inicio,
            periodo_fin: this.periodo_fin,
            poblacion: this.poblacion,
            id_actividad: this.actividad,
            valor_facturado: this.valor_facturado,
            observaciones: this.observaciones,
          };

          Swal.fire({
            title: "Esta seguro de finalizar y guardar esta factura ?",
            showCancelButton: true,
            icon: "question",
            confirmButtonText: "Sí, estoy seguro",
            cancelButtonText: "No",
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return facturaService
                .saveEpsIvoice(factura)
                .then((response) => {
                  return response.data;
                })
                .catch((error) => {
                  Swal.showValidationMessage(
                    `Request failed: ${error.statusText}`
                  );
                });
            },
            allowOutsideClick: () => !Swal.isLoading(),
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                text: `Factura Guardada con Exito, Factura Nro. ${result.value.prefijo}${result.value.numero}`,
                icon: "success",
              });
            }
          });
        }
      } catch (ex) {
        console.error(ex);
        Swal.fire({
          text:
            "Ocurrio un error al guardar si el problema persiste contacte con el administrador del sistema",
          icon: "error",
        });
      }
    },
    async getActivities() {
      try {
        let url = "/factura/actividad";
        let response = await service.get(url);
        this.actividades = response.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
  watch: {
    eps: {
      handler: function (newValue) {
        this.contratos = [];
        if (!isEmpty(newValue)) {
          this.contratos = newValue.contratos;
        }
      },
      deep: true,
    },
  },
  computed: {},
};
</script>
